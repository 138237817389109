import React, { useContext, useState } from 'react';
import { BASE_URL } from '../../BaseUrl';

//functions
import {
    _t,
    getSystemSettings,
    pageLoading
} from "../../../src/functions/Functions";

//   context value
import { SettingsContext, loading, setLoading } from "../../contexts/Settings";
import {NavLink} from "react-router-dom";
const Footer = () => {
    //getting context values here
    let { templateOneSettings, loading } = useContext(SettingsContext);
    // use state here

    //getting context values here
    let {
        generalSettings,
    } =
        useContext(SettingsContext);

    return (
        <>

            <footer className="ht-footer padding-bottom-50 padding-top-50">

                <div className="htf-shapes">
                    <img className="drs1 item-bounce d-none d-md-block" src="website/images/img/htshape22.png"
                         alt=""/>
                    <img className="drs2 d-none d-md-block" src="website/images/img/htshape17.png" alt=""/>
                    <img className="drs3 d-none d-md-block" src="website/images/img/htshape2.png" alt=""/>
                    <img className="drs4 item-animateTwo d-none d-md-block" src="website/images/img/htleaf2.png" alt=""/>
                </div>
                <div className="container">
                    <div className="divider">
                        <hr/>
                    </div>
                    <div className="ht-footer-top">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 margin-bottom-30">
                                <div className="widget text-center text-sm-left">
                                    <div className="logo">
                                        <NavLink
                                            to={{ pathname: "/refresh", state: "/" }}
                                            exact
                                            className="t-link w-100"
                                            key="logokey"
                                        >
                                            <img
                                                src={getSystemSettings(generalSettings, "type_logo")}
                                                alt="logo"
                                            />
                                        </NavLink>
                                    </div>
                                    {/* <a href="#" className="ht-link">email address</a>
                                    <a href="#" className="ht-email"><span><i className="fas fa-envelope-open-text"></i></span> info@greencorneraz.com</a> */}
                                    {/*<a href="#" className="ht-link">google map</a>*/}
                                    {/* <div className="social-box">
                                        <span><a href="#"><i className="fab fa-facebook"></i></a></span>
                                        <span><a href="#"><i className="fab fa-instagram"></i></a></span>
                                        <span><a href="#"><i className="fab fa-twitter"></i></a></span>
                                        <span><a href="#"><i className="fab fa-youtube"></i></a></span>
                                        <span><a href="#"><i className="fab fa-linkedin"></i></a></span>

                                    </div> */}
                                </div>
                            </div>
                            {/*<div className="col-lg-3 col-md-6 col-sm-6 margin-bottom-30">*/}
                            {/*    <div className="widget text-center text-sm-left">*/}
                            {/*        <h6>Site Links</h6>*/}
                            {/*        <ul>*/}
                            {/*            <li><a href="/about-us">about us</a></li>*/}
                            {/*            <li><a href="/gallery">gallery</a></li>*/}
                            {/*            <li><a href="/menu">menu</a></li>*/}
                            {/*            <li><a href="/catering">catering</a></li>*/}
                            {/*            <li><a href="#">privacy policy</a></li>*/}
                            {/*            <li><a href="#">terms & conditions</a></li>*/}
                            {/*        </ul>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* <div className="col-lg-3 col-md-6 col-sm-6 margin-bottom-30">
                                <div className="widget text-center text-sm-left">
                                    <h6>Chandler branch</h6>
                                    <h5><span><i className="fas fa-phone-square-alt"></i></span>(480) 553-7011</h5>
                                    <ul>
                                        <li>Mon-Sat 11:00 am - 9:00 pm</li>
                                        <li>Sun 11:00 am - 7:00 pm</li>
                                    </ul>
                                    <a href="https://goo.gl/maps/myUzb4N2X1v" className="ht-email"><span><i
                                        className="fas fa-map"></i></span>
                                         1065 W. Queen Creek Rd., # 2
                                        Chandler, AZ 85248</a>
                                </div>
                            </div> */}

                            {/* <div className="col-lg-3 col-md-6 col-sm-6 margin-bottom-30">
                                <div className="widget text-center text-sm-left">
                                    <h6>Mesa branch</h6>
                                    <h5><span><i className="fas fa-phone-square-alt"></i></span>(480) 835-2313</h5>
                                    <ul>
                                        <li>Mon-Sat 10:30 am - 9:00 pm</li>
                                        <li>Sun 11:00 am - 7:00 pm</li>
                                    </ul>
                                    <a href="https://goo.gl/maps/nGzhDx8799P2" className="ht-email"><span><i
                                        className="fas fa-map"></i></span>
                                        1010 W Southern Ave #7,
                                        Mesa, AZ 85210</a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 margin-bottom-30">
                                <div className="widget text-center text-sm-left">
                                    <h6>Scottsdale Branch</h6>
                                    <h5><span><i className="fas fa-phone-square-alt"></i></span>(480) 698-8090</h5>
                                    <ul>
                                        <li>Mon-Sat 11:00 am - 9:00 pm</li>
                                        <li>Sun 11:00 am - 7:00 pm</li>
                                    </ul>
                                    <a href="https://goo.gl/maps/Ts2qExtS5oavhGpTA" className="ht-email"><span><i
                                        className="fas fa-map"></i></span>
                                        10392 N Scottsdale Rd #1433,
                                        Scottsdale, AZ 85253</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/*section divider*/}

                    <div className="divider">
                        <hr/>
                    </div>

                    <div className="ht-footer-bottom">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-4 col-12">
                                <div className="ht-copyright-area text-center text-md-left">
                                    <p>copyright @ 2021 <a href="index.html">Tech Performance USA</a></p>
                                </div>
                            </div>
                            {/*<div className="col-lg-5 col-md-8 col-12">*/}
                            {/*    <div className="ht-payment">*/}
                            {/*        <a href="checkout.html"> <img src="website/images/img/payment2.png" alt=""/></a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </footer>

            {/* <!-- ToTop Button --> */}
            <button
                className="scrollup scrollup2"
                onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
            >
                <i className="fas fa-angle-up"></i>
            </button>
        </>
    );
}

export default Footer;
