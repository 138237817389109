import React, { useContext, useState } from 'react';
import { BASE_URL } from '../../BaseUrl';

//functions
import {
    _t, deleteCookie, getCookie,
    getSystemSettings,
    pageLoading
} from "../../../src/functions/Functions";

//   context value
import { SettingsContext, loading, setLoading } from "../../contexts/Settings";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../contexts/User";
import {RestaurantContext} from "../../contexts/Restaurant";
import {FoodContext} from "../../contexts/Food";
const Header = () => {
    //getting context values here
    let { templateOneSettings, loading } = useContext(SettingsContext);
    // use state here

    const { t, i18n } = useTranslation();

    //getting context values here
    let {
        generalSettings,
    } =
        useContext(SettingsContext);
    //auth user
    const { authUserInfo } = useContext(UserContext);
    const [deliverymenShow, setdeliverymenShow] = useState(false);
    //logout
    const handleLogout = () => {
        deleteCookie();
    };

//dynamic style
    const style = {
        logo: {
            backgroundImage:
                generalSettings &&
                `url(${getSystemSettings(generalSettings, "type_logo")})`,
        },
        currency: {
            backgroundColor:
                generalSettings && getSystemSettings(generalSettings, "type_clock"),
            color:
                generalSettings && getSystemSettings(generalSettings, "type_color"),
        },
    };

    return (
        <>
            <header className="header3">
                {/* <div className="header-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12 d-flex flex-wrap justify-content-between">
                                <div className="contact-box">
                                  <span>
                                    <a href="tel:4805537011"><i className="fas fa-phone-square-alt"></i> Chandler : (480) 553-7011</a>
                                  </span>
                                    <span>
                                    <a href="tel:4808352313"><i className="fas fa-phone-square-alt"></i> Mesa : (480) 835-2313</a>
                                  </span>
                                    <span>
                                    <a href="tel:480698-8090"><i className="fas fa-phone-square-alt"></i> Scottsdale : (480) 698-8090</a>
                                  </span>
                                    <span>
                                    <a href="mailto:info@greencorneraz.com"><i className="fas fa-envelope-open-text"></i>
                                      info@greencorneraz.com</a></span>
                                </div>
                                <div className="social-box">
                                    <span><a href="#"><i className="fab fa-twitter"></i></a></span>
                                    <span><a href="https://www.facebook.com/Green-Corner-Restaurant-Chandler-181587249308356/"><i className="fab fa-facebook-f"></i></a></span>
                                    <span><a href="#"><i className="fab fa-linkedin-in"></i></a></span>
                                    <span><a href="#"><i className="fab fa-instagram"></i></a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* header-bottom  */}
                <div className="header-bottom home2-header-bottom margin-top-20">
                    <div className="container position-relative">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-1 col-md-2 col-sm-4 col-8 margin-bottom-20">
                                <div className="logo">
                                    {window.location.pathname === "/menu" ? (
                                        <NavLink
                                            to={{ pathname: "/refresh", state: "/" }}
                                            exact
                                            className="t-link w-100"
                                            key="logokey"
                                        >
                                            <img
                                                src={getSystemSettings(generalSettings, "type_logo")}
                                                alt="logo"
                                            />
                                        </NavLink>
                                    ) : (
                                        <NavLink
                                            to="/"
                                            exact
                                            className="t-link w-100"
                                            key="logokey"
                                        >
                                            <img
                                                src={getSystemSettings(generalSettings, "type_logo")}
                                                alt="logo"
                                            />
                                        </NavLink>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-9 d-none d-lg-block">
                                <nav id="mobile-menu">
                                    <ul className="main-menu main-menu3">
                                        {/* <li>
                                            <a href="/">{_t(t("home"))}</a>
                                        </li> */}
                                        {/* <li><a href="#">{_t(t("about"))}<span><i className="fas fa-angle-down"></i></span></a>
                                        <ul className="submenu">
                                            <li><a href="/about-us">{_t(t("about"))}</a></li>
                                            <li><a href="/gallery">{_t(t("gallery"))}</a></li>
                                            <li><a href="/reviews">{_t(t("reviews"))}</a></li>
                                        </ul>
                                        </li> */}
                                        <li>
                                            <a href="/menu">{_t(t("home"))}</a>
                                        </li>
                                        {/* <li>
                                            <a href="/catering">{_t(t("catering"))}</a>
                                        </li>
                                        <li>
                                            <a href="/blog">{_t(t("blog"))}</a>
                                        </li>
                                        <li>
                                            <a href="/hiring">{_t(t("hiring now"))}</a>
                                        </li>
                                        <li>
                                            <a href="/locations">{_t(t("locations"))}</a>
                                        </li> */}
                                        {authUserInfo &&
                                        authUserInfo.details &&
                                        authUserInfo.details.user_type === "customer" && (
                                            <li>
                                                <NavLink to="/profile">{_t(t("Profile"))}</NavLink>
                                            </li>
                                        )}

                                        {deliverymenShow == true && authUserInfo && !authUserInfo.details && (
                                            <li>
                                                <NavLink to="/delivery-man-registration">
                                                    {_t(t("deliveryman"))}
                                                </NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-lg-2 col-md-9 col-sm-8 col-12">
                                <div className="customer-area3 d-flex align-items-center justify-content-center justify-content-md-end">
                                    {getCookie() === undefined ? (
                                        <NavLink to="/login" className="btn2">
                                            {_t(t("Login"))}
                                        </NavLink>
                                    ) : (
                                        <>
                                            {authUserInfo &&
                                            authUserInfo.details &&
                                            authUserInfo.details.user_type !== "customer" ? (
                                                <NavLink to="/dashboard" className="btn2">
                                                    {_t(t("Dashboard"))}
                                                </NavLink>
                                            ) : (
                                                <button className="btn2" onClick={handleLogout}>
                                                    {_t(t("Logout"))}
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mobile-menu home2 home2-mobile-menu"></div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
